import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const router = new Router({
	// mode: 'history',
	routes: [{
			path: '/',
			component: () => import('@/views/main/index'),
			hidden: true
		},

		{
			path: '/404',
			component: () => import('@/views/404'),
			hidden: true
		},

		{
			path: '/music/:category',
			name: 'music',
			component: () => import('@/views/music/index.vue'),
		},

		{
			path: '/cartoon/:category',
			name: 'cartoon',
			component: () => import('@/views/cartoon/index.vue'),
		},

		{
			path: '/detail/:id',
			name: 'detail',
			component: () => import('@/views/cartoon/cartoonDetail.vue'),
		},

		{
			path: '/user',
			name: 'user',
			component: () => import('@/views/user/index.vue'),
		},

		{
			path: '/login',
			name: 'login',
			component: () => import('@/views/login/index.vue'),
		},

		{
			path: '/sign',
			name: 'sign',
			component: () => import('@/views/login/register.vue'),
		},

		{
			path: '/tweet/:id',
			name: 'tweet',
			component: () => import('@/views/tweet/index.vue'),
		},

		{
			path: '/chat',
			name: 'chat',
			component: () => import('@/views/chat/index.vue'),
		},

		{
			path: '/test',
			name: 'test',
			component: () => import('@/views/work/index.vue'),
		},
		
		{
			path: '/testList',
			name: 'testList',
			component: () => import('@/components/work/netCar.vue'),
		},
		
		{
			path: '/videoProve1',
			name: 'videoProve1',
			component: () => import('@/components/work/videoProve1.vue'),
		},
		
		{
			path: '/videoProve2',
			name: 'videoProve2',
			component: () => import('@/components/work/videoProve2.vue'),
		},
		
		{
			path: '/dead',
			name: 'Dead',
			component: () => import('@/components/work/dead.vue'),
		},
		
		{
			path: '/bool',
			name: 'Bool',
			component: () => import('@/components/work/bool.vue'),
		},
		
		{
			path: '/contract',
			name: 'Contract',
			component: () => import('@/components/work/contract.vue'),
		},
		
		{
			path: '/week',
			name: 'Week',
			component: () => import('@/components/work/week.vue'),
		},
		

		// 404 page must be placed at the end !!!
		{
			path: '*',
			redirect: '/404',
			hidden: true
		}
	]
})

const VueRouterPush = Router.prototype.push
Router.prototype.push = function push(to) {
	return VueRouterPush.call(this, to).catch(err => err)
}


export default router
